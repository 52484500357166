// class based component just in case we ever need to add state to it
import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../../images/nav-logo.svg"
import { styles } from "../../../utils"
import { FaBars } from "react-icons/fa"
import styled from "styled-components"

// we passed down props to this component
export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar } = this.props
    return (
      <HeaderWrapper>
        <Link to="/">
          <img src={logo} alt="Foragers Canteen" className="nav-logo" />
        </Link>
        <FaBars
          className="toggle-icon"
          onClick={() => {
            handleNavbar()
          }}
        />
      </HeaderWrapper>
    )
  }
}

// We have access to variables inside
// template literals. It's just JS derp!
const HeaderWrapper = styled.div`
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .toggle-icon {
    font-size: 1.75rem;
    color: ${styles.colors.navLinkWhite};
    cursor: pointer;
  }
  .nav-logo {
    width: 8rem;
    height: auto;
  }
  @media (min-width: 992px) {
    .toggle-icon {
      display: none;
    }
  }
`
