import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

function BannerCenter({ title, subtitle, children }) {
  return (
    <DefaultBanner>
      <h1 className="title">{title}</h1>
      <h3 className="subtitle">{subtitle}</h3>
      {children}
    </DefaultBanner>
  )
}
function BannerLeft({ title, subtitle, children }) {
  return (
    <LeftBanner>
      <h1 className="title">{title}</h1>
      <h3 className="subtitle">{subtitle}</h3>
      {children}
    </LeftBanner>
  )
}

const DefaultBanner = styled.div`
  width: 90vw;
  margin-bottom: 3rem;
  text-align: center;

  h1 {
    ${styles.textBannerTitle};
    color: ${styles.colors.mainWhite};
    transform: rotate(-5deg);
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-shadow: 0.025em 0.025em rgba(0, 0, 0, 0.5);
    line-height: 1.1em;
  }
  h3 {
    ${styles.textBannerSubTitle};
    color: ${styles.colors.mainWhite};
    ${styles.letterSpacing({ spacing: "0.15rem" })};
    text-shadow: 0.025em 0.025em rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
  }
  p {
    font-size: 1.75rem;
  }

  hr {
    background: tan;
    height: 0.1em; 
    margin: 1.5rem 0;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 4rem;
    }
    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
`

const LeftBanner = styled(DefaultBanner)`
  text-align: left !important;
  h1 {
    margin-bottom: 3.5rem !important;
  }
`

BannerCenter.defaultProps = {
  title: "Foragers Canteen",
  subtitle: "Delicious Food",
}

BannerLeft.defaultProps = {
  title: "Foragers Canteen",
  subtitle: "Delicious Food",
}

export { BannerCenter, BannerLeft }
