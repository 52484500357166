import styled from "styled-components"
import React from "react"
import img from "../images/bcg/homeBcg.jpeg"

// function based component
// pass down props to this component ({img and children})
function HomeHeader({ img, children }) {
  return <IndexHeader img={img}>{children}</IndexHeader>
}
function PageHeader({ img, children }) {
  return <IndexHeader img={img}>{children}</IndexHeader>
}
function AboutHeader({ img, children }) {
  return <BlendHeader img={img}>{children}</BlendHeader>
}

const IndexHeader = styled.header`
  min-height: calc(100vh - 88.78px);
  background-image: url(${props => props.img});
  background-repeat: no-repeat; 
  background-position: center;
  background-attachment: local;       
  webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height:100%;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  min-height: 80vh;
`

const BlendHeader = styled(IndexHeader)`
  background: linear-gradient(rgba(178, 34, 34, 0.8), rgba(178, 34, 34, 0.6)),
    url(${props => props.img}) center/cover fixed no-repeat;
  background-blend-mode: darken;

  h1 {
    padding: 3rem 0 3rem 0;
  }
`

const TanHeader = styled(IndexHeader)`
  background-color: #947e61;

  h1 {
    padding: 3rem 0 3rem 0;
  }
`

// fallback default images
HomeHeader.defaultProps = {
  img: img,
}
PageHeader.defaultProps = {
  img: img,
}

export { HomeHeader, PageHeader, AboutHeader, TanHeader }
