import styled from "styled-components"
import { styles } from "../utils"

// not a class based or a functional component
const BannerButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.25em;
  margin-top: 3rem;
  ${styles.transition({})};
  background-color: firebrick;
  border-color: indianred;
  font-family: "DEVIOUS", sans-serif;
  font-weight: 600;
  font-size: 2em;
  line-height: 1.5em;
  color: #fff;
  &:hover {
    background-color: #871a1a !important;
    transition-duration: 0.25s;
    cursor: pointer;
  }
  a {
    color: #fff;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
`

const SectionButton = styled(BannerButton)`
  color: ${styles.colors.mainBlack};
  ${styles.border({ color: `${styles.colors.mainBlack}` })};
  &:hover {
    background: ${styles.colors.mainBlack};
    color: ${styles.colors.mainYellow};
    cursor: pointer;
  }
`

export { BannerButton, SectionButton }
