import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { styles } from "../../../utils"

export default class NavbarLinks extends Component {
  state = {
    links: [
      {
        id: 0,
        path: "/",
        name: "Home",
      },
      {
        id: 1,
        path: "/menu/",
        name: "Menu",
      },
      {
        id: 2,
        path: "/takeout/",
        name: "Takeout",
      },
      {
        id: 3,
        path: "/catering/",
        name: "Catering",
      },
      {
        id: 4,
        path: "/about/",
        name: "About",
      },
    ],
  }
  render() {
    // console.log(this.props.navbarOpen)
    return (
      <LinkWrapper open={this.props.navbarOpen}>
        {this.state.links.map(item => {
          // returning JSX
          return (
            <li key={item.id}>
              <Link to={item.path} className="nav-link">
                {item.name}
              </Link>
            </li>
          )
        })}
      </LinkWrapper>
    )
  }
}
// styled components can accept props too!
// when passing props to styled components
// must pass it as a function (see height)
const LinkWrapper = styled.ul`
  padding: 0;
  margin-bottom: 0;
  li {
    list-style-type: none;
    text-align: center;
  }
  li:nth-child(even) {
    background-color: #9d1e1e;
    border-top: 0.2em solid #9d1e1e;
  }
  li:nth-child(even) a {
    transform: rotate(-1.5deg);
  }
  li:nth-child(odd) a {
    transform: rotate(1.5deg);
  }
  .nav-link {
    ${styles.textNavLink};
    display: block;
    text-decoration: none;
    padding: 0.5rem 0.85rem 0.5rem 0.85rem;
    color: ${styles.colors.navLinkWhite};
    font-weight: 700;
    text-transform: uppercase;
    transition-duration: 0.2s;

    cursor: pointer;
    ${styles.transDefault};
    &:hover {
      color: ${styles.colors.mainWhite};
      padding: 0.5rem 1rem 0.5rem 1.3rem;
    }
  }
  height: ${props => (props.open ? "242px" : "0px")};
  overflow: hidden;
  ${styles.transObject({})};
  @media (min-width: 992px) {
    li:nth-child(even) {
      background-color: firebrick;
      border: none;
    }
    li:nth-child(even) a {
      transform: none;
    }
    li:nth-child(odd) a {
      transform: none;
    }
    height: auto;
    display: flex;
    text-align: center;
    margin: 0 auto;
    .nav-link:hover {
      padding: 0.5rem 0.85rem 0.5rem 0.85rem;
    }
  }
`
