import React, { Component } from "react"
import styled from "styled-components"
import NewsletterSignUpForm from "./NewsletterSignUpForm"
import { styles } from "../../utils"

export default class Newsletter extends Component {
  render() {
    return (
      <NewsletterWrapper>
        <div className="container newsletter" align="center">
          <h3>Hey You...</h3>
          <h4>Join Our Newsletter!</h4>
          <div className="row px-0 py-5 justify-content-center" >
            <NewsletterSignUpForm></NewsletterSignUpForm>
          </div>
        </div>
      </NewsletterWrapper>
    )
  }
}
const NewsletterWrapper = styled.section`
  background-color: firebrick;
  padding: 2rem 0;
  text-align: center;
  .newsletter h3 {
    ${styles.textQuickInfoTitle};
    color: ${styles.colors.mainTan};
    text-shadow: 0.02em 0.02em black;
    font-size: 2.5rem;
    color: tan;
    font-size: 2em;
    text-decoration: none;
  }
  .newsletter h4 {
    ${styles.textBannerSubTitle};
    color: ${styles.colors.mainWhite};
    font-size: 3.5rem;
    text-shadow: 0.025em 0.025em black;
    color: white;
  }
  .newsletter:hover h4 {
    text-shadow: 0.03em 0.03em black;
  }
  div.newsletter ._form_1 {
    max-width: 100% !important;
  }
  @media (max-width: 768px) {
    .newsletter h3 {
      font-size: 1.75rem;
    }
    .newsletter h4 {
      font-size: 2.2rem;
    }
  }
`
