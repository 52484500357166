import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import Navbar from "../components/globals/navbar"
import Footer from "../components/globals/Footer"
import Newsletter from "../components/globals/Newsletter"
import * as fonts from "../fonts"

import "./bootstrap.min.css"
import "../sass/layout.scss"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navbar />
      {children}
      <Newsletter />
      <Footer />
    </React.Fragment>
  )
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'DEVIOUS';
    font-style: normal;
    font-weight: normal;
    src: local('DEVIOUS'), url('${fonts.DEVIOUSwoff}') format('woff');
  }
  @font-face {
    font-family: 'Satisfy-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Satisfy-Regular'), url('${fonts.SatisfyRegular}') format('truetype');
  }
  @font-face {
    font-family: 'Arvo-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Arvo-Regular'), url('${fonts.ArvoRegular}') format('truetype');
  }
  @font-face {
    font-family: 'TheGoldsmithVintage';
    font-style: normal;
    font-weight: normal;
    src: local('TheGoldsmithVintage'), url('${fonts.GoldsmithVintage}') format('otf');
  }
  html {
    width: 100%;
  }
  body {
    width: 100%;
    font-family: 'Arvo-Regular', serif !important;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
