export const colors = {
  mainWhite: `#fff`,
  navLinkWhite: `rgba(255,255,255,.5);`,
  mainBlack: `#262626`,
  mainYellow: `#d2aa5c`,
  mainTan: `tan`,
  darkBrown: `#320909`,
  mainYellow2: `#F2AF29`,
  mainGrey: `#474747`,
}
export const textDefault = `font-family: 'Arvo-Regular', sans-serif;`
export const textSlanted = `font-family: 'Caveat', cursive;`
export const textNavLink = `font: normal 2em/1em DEVIOUS, sans-serif;`
export const textBannerTitle = `font: normal 6em/1.25em Satisfy-Regular, sans-serif;`
export const textBannerSubTitle = `font: normal 2.75em/1em DEVIOUS, sans-serif;`
export const textQuickInfoTitle = `font: normal 2em Satisfy-Regular, cursive, sans-serif;`

export const transDefault = "transition:all 0.2s ease-in-out"
export const transFunction = (
  property = "all",
  time = "0.3s",
  type = "linear"
) => {
  return `transition:${property} ${time} ${type}`
}
export const transObject = ({
  property = "all",
  time = "0.3s",
  type = "ease-in-out",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const transition = ({
  property = "all",
  time = "0.3s",
  type = "ease-in-out",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const border = ({
  width = "0.15rem",
  type = "solid",
  color = "white",
}) => {
  return `border:${width} ${type} ${color}`
}

export const letterSpacing = ({ spacing = "0.1rem" }) => {
  return `letter-spacing:${spacing}`
}
